import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const LoginCognito = React.lazy(() =>
  import("./views/LoginCognito/LoginCognitoNative.js")
);
const LogoutCognito = React.lazy(() =>
  import("./views/LogoutCognito/LogoutCognito.js")
);
const HandleEmailSignIn = React.lazy(() =>
  import("./views/HandleEmailSignIn/HandleEmailSignIn")
);
const HandleAuthSignin = React.lazy(() =>
  import("./views/HandleAuthSignIn/HandleAuthSignIn")
);

const Gated = React.lazy(() => import("./views/PageGated/Gated"));
const BASE_URL = `${process.env.REACT_APP_COGNITO_URL}`;
const USER_ENDPOINT = `${BASE_URL}/oauth2/userInfo`;
const LOCAL_STORAGE_PREFIX =
  "CognitoIdentityServiceProvider.1o2g5ffrtd70h7kkludqdh6aqi.";

function App() {
  const [currentPath, setcurrentPath] = useState(window.location.href);
  const [user, setUser] = useState(
    localStorage.getItem("username") ||
      localStorage.getItem(
        "CognitoIdentityServiceProvider.1o2g5ffrtd70h7kkludqdh6aqi.LastAuthUser"
      )
  );

  useEffect(() => {
    async function initializeUser() {
      const accessToken = localStorage.getItem(
        `${LOCAL_STORAGE_PREFIX}${user}.accessToken`
      );
      if (accessToken) {
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem(
          "id_token",
          localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${user}.idToken`)
        );
        localStorage.setItem(
          "refresh_token",
          localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${user}.refreshToken`)
        );
        localStorage.setItem(
          "userData",
          localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${user}.userData`)
        );
        const userdata = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userdata);
        const mondayClientBoardAttribute = parsedUserData.UserAttributes.find(
          (attribute) => attribute.Name === "custom:mondayClientBoard"
        );
        const mondayClientBoardValue = mondayClientBoardAttribute
          ? mondayClientBoardAttribute.Value
          : null;

        const isadminAttribute = parsedUserData.UserAttributes.find(
          (attribute) => attribute.Name === "custom:isadmin"
        );
        const isadminValue = isadminAttribute ? isadminAttribute.Value : null;

        const driveIdAttribute = parsedUserData.UserAttributes.find(
          (attribute) => attribute.Name === "custom:driveId"
        );
        const driveIdValue = driveIdAttribute ? driveIdAttribute.Value : null;

        const response = await fetch(
          "https://6wf65an6rtdjdj2ghsrgqca2vq0suhtb.lambda-url.us-east-1.on.aws/"
        );
        const data = await response.json();
        localStorage.setItem(
          "microsoft_graph_token",
          data.idToken["https://dauntless-arc/microsoft_graph_token"]
        );
        localStorage.setItem("driveId", driveIdValue);
        localStorage.setItem("isadmin", isadminValue);
        localStorage.setItem("mondayClientBoard", mondayClientBoardValue);
      }
    }

    initializeUser();
  }, []);
  
  const [isadmin, setisAdmin] = useState(
    localStorage.getItem("isadmin") || false
  );
  const [gatenew, setgatenew] = useState(
    localStorage.getItem("isgated") || false
  );

  return (
    <>
      {!user && (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/login" element={<LoginCognito />} />
              <Route path="/auth" element={<HandleAuthSignin />} />
              <Route
                path="/sign-in/:challenge"
                element={<HandleEmailSignIn />}
              />
              <Route path="/error" element={<Gated />} />
              <Route path="/logout" element={<LogoutCognito />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      )}

      {user && gatenew === "true" && (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<Gated />} />
              <Route path="/logout" element={<LogoutCognito />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/error" element={<Gated />} />
              <Route path="*" element={<Gated />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      )}

      {user && isadmin === "true" && gatenew !== "true" && (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/logout" element={<LogoutCognito />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/error" element={<Gated />} />
              <Route path="*" element={<DefaultLayout user={user} />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      )}

      {user && isadmin !== "true" && gatenew !== "true" && (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/logout" element={<LogoutCognito />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/error" element={<Gated />} />
              <Route path="*" element={<DefaultLayout user={user} />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
