import { Amplify, Auth } from "aws-amplify";

export const config = {
  Auth: {
    region: "us-east-1",
    userPoolId: `${process.env.REACT_APP_COGNITO_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
    authenticationFlowType: "USER_SRP_AUTH",
  },
};

Amplify.configure(config);
